<template>
  <div class="c-list" :class="classes">
    <c-stepper
      :selected="active"
      target-el=".c-info-card > .wrapper > .c-info-header > .c-employee-info"
      select-first
    >
      <c-info-card
        v-for="(item, index) in data"
        v-bind="item"
        :key="(((item || {}).id || {}).value) || item.id || index"
        :grouped="true"
        :collapse="collapse"
        :borderless="borderless"
        :align-info="alignInfo"
        :action-size="actionSize"
        :title-component="item.titleComponent || 'c-employee-info'"
        :active="isMobile ? false : index == active"
        :theme="getTheme(index)"
        :highlighted-field="highlightedField"
        :has-header="hasHeader"
        @toggle="onToggle(index)"
        @add="$emit('add', item)"
        @edit="$emit('edit', item)"
        @extra="$emit('extra', item)"
        @remove="$emit('remove', item)"
      >
        <template v-for="fieldType in Object.keys($scopedSlots)" v-slot:[fieldType]="{ field }">
          <slot :name="fieldType" :field="field" />
        </template>

        <slot name="actions" slot="actions" />
      </c-info-card>
    </c-stepper>
  </div>
</template>

<script>
import { MediaQuery } from '@convenia/mixins'

export default {
  name: 'CList',

  mixins: [ MediaQuery ],

  props: {
    /**
     * An array of object. Each object has props from the CInfoCard component.
     */
    data: {
      type: Array,
      default: () => ([])
    },

    /**
     * Change card styles to borderless.
     */
    borderless: Boolean,

    /**
     * Whether the cards can toggle open/close.
     */
    collapse: Boolean,

    /**
     * Align the card title with the fields.
     */
    alignInfo: Boolean,

    canEdit: Boolean,

    theme: {
      type: String,
      default: null,
    },

    /**
     * Changes the size of the action buttons in the header to 30px or 40px,
     * respectively, proportionally changes the icon size as well
     */
    actionSize: {
      type: String,
      validator: v => !v || [ 'sm', 'md' ].includes(v),
      default: null,
    },

    highlightedField: {
      type: Object,
      default: null,
    },

    /**
     * Check whether it has a header or not.
     */
    hasHeader: {
      type: Boolean,
      default: true
    },
  },

  data: () => ({ active: 0 }),

  computed: {
    classes () {
      return {
        '-borderless': this.borderless,
        '-hide-stepper': this.hideStepper,
      }
    },

    hideStepper () {
      return !this.collapse || this.borderless
    }
  },

  methods: {
    getTheme (index) {
      if (!this.theme) return index === 0 ? 'primary' : 'default'

      return this.theme
    },

    onToggle (index) {
      this.active = this.active !== index ? index : null
      this.$emit('toggle', this.active)
    },

    canRemoveFiles (item) {
      return (item.removable === undefined) || item.removable
    }
  }
}
</script>

<style lang="scss">
.c-list {
  width: 100%;

  & > .c-stepper > .content > .c-info-card {
    margin-top: 20px;
    @include responsive(xs-mobile, mobile) {
      margin-top: 10px;
      & > .wrapper > .c-info-header { padding-left: 10px; }
    }

    &:first-child { margin-top: 0; }
  }

  & > .c-stepper > .bar {
    margin: 0 20px 0 10px;
    @include responsive(tablet, desktop) { margin: 0px 30px 0px 10px; }
  }

  &.-hide-stepper {
    & > .c-stepper > .bar { display: none; }
  }

  &.-borderless {
    & > .c-stepper > .content > .c-info-card {
      margin-top: 0;

      &.-opened {
        margin-top: 30px;
        @include responsive(xs-mobile, mobile) { margin-top: 15px; }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
