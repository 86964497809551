import { headers, dataURItoBlob } from '@modules/rest'

import * as get from '@graphql/middleware/queries'
import request from '@graphql/middleware'

export async function getPhoto (getters) {
  const employeeId = getters.getEmployeeUuid

  const [ err, data ] = await request(get.GetPhoto, { employeeId })
  const { photo } = data.employee || {}

  return [ err, photo ]
}

export async function uploadPhoto (getters, photo) {
  const employeeId = getters.getEmployeeUuid

  const body = new FormData()

  body.append('photo', dataURItoBlob(photo))

  return fetch(`${import.meta.env.VITE_EMPLOYEE_REST_API}/employees/${employeeId}/photo`, {
    method: 'POST',
    body,
    headers: headers([ 'Authorization', 'Accept' ])
  })
    .then(response => { if (!response.ok) throw response; return response.json() })
    .then(({ data }) => [ false, data ])
    .catch(err => err.json()
      .then(({ data }) => [ data, null ])
    )
}
