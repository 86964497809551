import store from '@store'

export function headers (filter = []) {
  const { token } = store.state.auth

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  }

  return !filter.length ? headers :
    filter.reduce((acc, key) => ({
      ...acc,
      [key]: headers[key]
    }), {})
}

const base64Extension = (encoded) => {
  const EXTENSIONS = {
    '/': 'jpg',
    'i': 'png',
    'R': 'gif',
    'U': 'webp',
    'J': 'pdf',
    'P': 'svg',
  }

  const [ , content = '' ] = encoded.split(';base64,') || []

  return EXTENSIONS[content.charAt(0)] || 'png'
}

export function dataURItoBlob (dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const ext = base64Extension(dataURI)

  return new File([ ia ], `photo.${ext}`, { type: mimeString });
}
