<template>
  <div class="c-historical">
    <div class="card-lists">
      <template v-for="({ divider, historicalChanges, placeholder }, i) of historicalCollections">
        <div
          v-if="!noDivider"
          :key="i"
          :class="[ 'divider', { '-placeholder': placeholder || forcePlaceholder } ]"
        >
          <c-divider :tag="divider" />
        </div>

        <card-list
          :key="divider"
          :class="[ 'card-list', { '-placeholder': placeholder || forcePlaceholder } ]"
          :group-index="i"
          :selected="selectedLists[i]"
          :historical-changes="historicalChanges"
          :header-templates="headerTemplates"
          :side-info-width="sideInfoWidth"
          :info-margin="infoMargin"
          :format-field="formatField"
          v-bind="$attrs"
          @edit="emitEdit"
          @remove="emitRemove"
          @toggle="onToggle"
        >
          <template #additional-info="{ content }">
            <!--
              @slot Displays the side info of each card
              @binding {string} sideInfo field of the Historical Change
              -->
            <slot name="side-info" :content="content.sideInfo" />
          </template>

          <template #header-content="{ headerContent }">
            <!--
              @slot The header of each card (desktop)
              @binding {object} The Historical Change of each card
              -->
            <slot name="header-content" :historical-change="headerContent" />
          </template>

          <template #header-before-actions="{ headerContent }">
            <!--
              @slot The header actions of each card
              @binding {object} The Historical Change of each card
              -->
            <slot name="header-before-actions" :historical-change="headerContent" />
          </template>
        </card-list>
      </template>
    </div>

    <info-popover
      v-if="isMobile && selected.mobile"
      v-bind="infoPopoverData"
      :popoverTarget="$el"
      :header-templates="headerTemplates"
      :format-field="formatField"
      @toggle="onToggle"
      @close="selected.mobile = null"
    >
      <template #header-content="{ headerContent }">
        <!--
          @slot The header of each card (mobile)
          @binding {object} The Historical Change of each card
          -->
        <slot name="header-content" :historical-change="headerContent" />
      </template>
    </info-popover>

  </div>
</template>

<script>
import { MediaQueryRoot } from '@convenia/mixins'

import CardList from './fragments/CardList'
import InfoPopover from './fragments/InfoPopover'

/**
 * A stepper like component used to display historical infos
  */

export default {
  name: 'CHistorical',

  mixins: [ MediaQueryRoot ],

  components: { InfoPopover, CardList },

  data () {
    return this.setInitialData()
  },

  props: {
    /**
     * A list of group of historical infos with a divider to differentiate them
     * @typedef { { divider: string, historicalChanges: HistoricalChange[] } } HistoricalCollection
     * @typedef { { id: string, highlight: boolean, action: string,
     *  sideInfo: string, additionalInfo: Object, fields: HistoricalField[] } } HistoricalChange
     * @typedef { { type: string, label: string,
     *  value: { string|{ newValue: string, oldValue: string } } } } HistoricalField
     * @type { HistoricalCollection[] }
     */
    historicalCollections: {
      required: true,
      type: Array
    },

    /**
     * Defines if the divider component will be displayed between the collections
     * @type { boolean }
     */
    noDivider: Boolean,

    /**
     * Defines the icon type and color for each key which references the action type
     * each historical change
     * @typedef { Object } HeaderTemplates
     * @property { Object.<string, { icon: string, theme: string }> }
     *
     * @type { HeaderTemplates }
     */
    headerTemplates: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Defines the width of the side infos
     * @type { number }
     */
    sideInfoWidth: { type: Number, default: 0 },

    /*
     * The margin to use in the CInfo component,
     * defines the width of the gap between a field's
     * value and the beginning of it's card.
     */
    infoMargin: {
      type: Number,
      default: 230
    },

    /**
     * Force placeholder style to all changes
     */
    forcePlaceholder: { type: Boolean },

    /**
     * Format fields function
     */
    formatField: { type: Function, default: null },
  },

  watch: {
    isMobile () {
      Object.assign(this.$data, this.setInitialData())
    }
  },

  computed: {
    infoPopoverData () {
      const { group, card } = (this.selected || {}).mobile
      const data = ((this.historicalCollections[group] || {})
        .historicalChanges || [])[card]

      return { ...data, ...this.$attrs }
    },

    selectedLists () {
      const { cards, mobile } = this.selected

      if (this.isMobile) {
        const mobileList = this.historicalCollections.map(() => [])

        if (!mobile) return mobileList

        mobileList[mobile.group || 0] = [ mobile.card ]
        return mobileList
      }

      return cards
    },
  },

  methods: {
    onToggle (card, group) {
      const { mobile, cards } = this.selected

      this.mobileToggle(mobile, card, group)
      this.desktopToggle(cards, card, group)

      /**
       * Toggle event on a historical card
       * @event toggle
       * @property { number } HistoricalChange index
       * @property { number } HistoricalGroup index
       */
      this.$emit('toggle', card, group)
    },

    emitEdit (card, group) {
      /**
       * Click in the edit action of a historical card
       * @event edit
       * @property { number } HistoricalChange index
       * @property { number } HistoricalGroup index
       */
      this.$emit('edit', card, group)
    },

    emitRemove (card, group) {
      /**
       * Click in the remove action of a historical card
       * @event remove
       * @property { number } HistoricalChange index
       * @property { number } HistoricalGroup index
       */
      this.$emit('remove', card, group)
    },

    mobileToggle (mobile, card, group) {
      this.selected.mobile = mobile ? null : { card, group }
    },

    desktopToggle (cards, card, group) {
      const groupCards = cards[group] || []

      const newCards = [ ...cards ]
      newCards[group] = groupCards.includes(card) ? [] : [ card ]

      this.selected.cards = newCards
    },

    setInitialData () {
      return {
        selected: {
          mobile: null,
          cards: [ [ 0 ] ],
        }
      }
    }
  }
}
</script>

<style lang="scss">
.c-historical {
  & > .card-lists > .-placeholder {
    opacity: 0.3;

    cursor: default;
    pointer-events: none;
  }

  & > .card-lists > .divider {
    margin-bottom: 32px;
  }

  & > .card-lists > .card-list {
    margin-bottom: 60px;

    &:last-of-type { margin-bottom: 10px; }
  }
}
</style>
