<template>
  <div
    :class="cardClasses"
    :style="[ cardStyles, sidebarStyles ]"
    @click="$emit('click', $event)"
  >
    <!-- @slot The card's content slot. -->
    <slot />
  </div>
</template>

<script>

/**
 * A Card component, basically just a fancy wrapper.
 */
export default {
  name: 'CCard',
  props: {
    /**
     * The background color of the card.
     */
    backgroundColor: {
      type: String,
      default: 'white'
    },

    /**
     * The color of the card's border.
     */
    borderColor: {
      type: [ String, Array, Boolean ],
      default: '',
    },

    /**
     * Sets the border color to a red gradient.
     */
    red: Boolean,

    /**
     * Sets the border color to a green gradient.
     */
    green: Boolean,

    /**
     * Sets the border color to a yellow gradient.
     */
    yellow: Boolean,

    /**
     * Sets the border color to a blue gradient.
     */
    blue: Boolean,

    /**
     * Sets the border color to a grey solid color.
     */
    gray: Boolean,

    /**
     * Sets the border color to a purple solid color.
     */
    purple: Boolean,

    /**
     * Removes the card's left-border.
     */
    noBorder: Boolean,

    /**
     * Disables the card's hover transition.
     */
    noHover: Boolean,

    /**
     * Inform position, first color and its percentage, second color and its percentage.
     * { image: '/img/square-pattern.svg', position: '90deg', colorOne: '#FFF',
     * percentageOne: '0%', percentageTwo: '100%' } + backgroundColor
     */
    gradientDetails: {
      type: Object,
      default: () => ({}),
    },

    /**
     * Adds image to the card.
     */
    image: {
      type: Object,
      default: () => ({}),
    }
  },

  computed: {
    cardStyles () {
      if (Object.keys(this.gradientDetails).length) {
        return {
          'background-image':
            `url(${this.gradientDetails.image}),
            linear-gradient(${this.gradientDetails.position},
            ${this.gradientDetails.colorOne} ${this.gradientDetails.percentageOne},
            ${this.backgroundColor} ${this.gradientDetails.percentageTwo})`,
        }
      }

      return {
        background: this.backgroundColor,
        ...(Object.keys(this.image).length ?
          {
            'background-image': `url(${this.image.url})`,
            'background-position': this.image.position,
          } : {}
        )
      }
    },

    cardClasses () {
      return [ 'c-card',
        {
          '-has-border': !this.noBorder,
          '-no-hover': this.noHover,
          '-red': this.red,
          '-green': this.green,
          '-yellow': this.yellow,
          '-blue': this.blue,
          '-gray': this.gray,
          '-purple': this.purple
        }
      ]
    },

    sidebarStyles () {
      const { borderColor: c, backgroundColor: b } = this
      const defaultBackground = b || 'white'
      const defaultBorder = 'none'

      const backgroundImage = Array.isArray(c) && !!c.length
        ? 'linear-gradient(' + [ b, b ].join(', ')
          + '), radial-gradient(ellipse at top left, '
          + c.join(', ') + ')'
        : defaultBackground

      const borderLeft = typeof c === 'string' && c.length > 1
        ? `4px solid ${c}` : defaultBorder

      return {
        ...(backgroundImage && { backgroundImage }),
        ...(borderLeft !== defaultBorder && { borderLeft })
      }
    }
  }
}
</script>

<style lang="scss">
.c-card {
  $border-radius: 5px;

  box-shadow: var(--box-shadow);
  border-radius: var(--base-border-radius);
  transition: all .3s ease;
  position: relative;

  @include responsive (tablet, desktop) {
    &:not(.-no-hover):hover { box-shadow: var(--box-shadow-hover); }
  }

  &::before {
    display: none;
    position: absolute;
    content: '';
    left: 0px;
    top: 0px;

    height: 100%;
    min-width: 4px;
    width: 4px;
    border-top-left-radius: var(--base-border-radius);
    border-bottom-left-radius: var(--base-border-radius);
  }

  &.-has-border {
    &::before {
      display: block;
      background: color-var()
    }

    &.-red::before { background: color-var(negative); }
    &.-green::before { background: color-var(positive); }
    &.-yellow::before { background: color-var(alert); }
    &.-blue::before { background: color-var(info); }
    &.-gray::before { background: color-var(text, base-30); }
    &.-purple::before { background: color-var(default); }
  }
}
</style>
